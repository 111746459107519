// Generated by nuxt-echarts

import { use } from 'echarts/core'
import {
  CanvasRenderer,
} from 'echarts/renderers'
import {
  BarChart,
  LineChart,
  PieChart,
} from 'echarts/charts'
import {
  DatasetComponent,
  GridComponent,
  TooltipComponent,
} from 'echarts/components'
import {
  LabelLayout,
  UniversalTransition,
} from 'echarts/features'

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  PieChart,
  DatasetComponent,
  GridComponent,
  TooltipComponent,
  LabelLayout,
  UniversalTransition,
])